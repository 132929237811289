<template lang="pug">
  table.base-table(cellspacing="0")
    thead.base-table__header
      tr
        th(v-for="(header, index) in headers" :key="index")
          h5.text-label.align-left {{ header }}
    tbody.base-table__body
      slot(name="tbody")
    tfoot.base-table__footer
      slot(name="tfoot")
</template>

<script>
export default {
  name: 'BaseTable',

  props: {
    headers: Array
  }
}
</script>

<style lang="scss">
.base-table {
  thead tr th {
    padding: 10px 5px;
    border-bottom: 1px solid $border-color;

    &:first-child {
      padding-left: 10px;
    }
  }
}
</style>